
import { Component, Vue } from 'vue-property-decorator'
import { WeatherQualityDetail } from '../../types/weatherQuality'
import { directionTransition } from '../../utils/formatData'

@Component({
  name: 'DetailList',
  filters: {
    directionFilter (value: string) {
      return directionTransition(value)
    }
  }
})
export default class DetailList extends Vue {
  private info = {
    deviceNumber: ''
  }

  private loading = false
  private dataList: Array<WeatherQualityDetail> = []
  page = 1
  size = 10
  total = 0

  get projectId () {
    return this.$route.params.projectId as string
  }

  created () {
    this.getData()
  }

  // 获取列表数据
  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<WeatherQualityDetail> }>(this.$apis.weatherQuality.selectWeatherQualityPage, {
      projectId: this.projectId,
      page: this.page,
      size: this.size,
      deviceNumber: this.info.deviceNumber
    }).then(res => {
      console.log('列表数据', res)
      this.total = res.total || 0
      this.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  toDetail (deviceId: string) {
    this.$router.push({ name: 'weatherQualityListDataAnalyse', params: { projectId: this.projectId, deviceId: deviceId } })
  }
}
